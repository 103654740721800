<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <!-- <el-form-item>
        <el-input v-model.trim="search.username" class="account">
          <template slot="prepend">昵称</template>
        </el-input>
      </el-form-item>-->
      <el-form-item>
        <el-input v-model="search.mobile" class="mobile" clearable>
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="search.status" placeholder="状态">
          <el-option
            v-for="item in statusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
      
        <el-button class="add-btn" @click="showAdd">添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        username: '',
        mobile: '',
        status: null
      },
      // 状态选项
      statusOpts: [
        { value: '', label: '请选择' },
        { value: 0, label: '正常' },
        { value: 1, label: '禁用' }
      ]
    };
  },
  methods: {
    searchList() {
      this.$emit('search', this.search);
    },
    showAdd() {
      this.$emit('show-add');
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .account.el-input {
    width: 160px;
  }
  .mobile.el-input {
    width: 220px;
  }
  .el-select {
    width: 100px;
  }
  .add-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
}
@media screen and (max-width: 480px) {
.el-form--inline .el-form-item{
  display: block;
  .el-input{
    width: 100%;
  }
  .el-form-item__content, .el-select{
    width: 100%;
  }
}
}
</style>