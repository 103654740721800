<template>
  <div class="add">
    <el-dialog
      :title="showStatus === 2 ? '编辑账号' : '添加账号'"
      :visible="!!showStatus"
      :width="width"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="100px"
        class="form"
        hide-required-asterisk
      >
        <template v-if="showStatus === 1">
          <!-- <el-form-item label="昵称" prop="nick">
            <el-input placeholder="请输入昵称" v-model="form.nick"></el-input>
          </el-form-item>-->
          <el-form-item label="账号" prop="username">
            <el-input v-model.trim="form.username" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model.number="form.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="微信号码" prop="wechat">
          <el-input v-model.trim="form.wechat" placeholder="请输入微信号码"></el-input>
        </el-form-item>
        <!-- <el-form-item label="QQ号码" prop="qq">
            <el-input v-model.number="form.qq" placeholder="请输入QQ号码"></el-input>
        </el-form-item>-->
        <el-form-item :label="showStatus === 2 ? '修改密码' : '输入密码'" prop="password">
          <el-input v-model.trim="form.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repsd">
          <el-input v-model.trim="form.repsd" placeholder="请确认密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="角色选择" prop="roleId">
          <el-select v-model="form.roleId" placeholder="选择角色">
            <el-option
              v-for="item in roleOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="当前状态" prop="status">
          <el-select v-model="form.status" placeholder="选择状态">
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div class="ft-sz-12 ft-bold">* 账号禁用后，该账号下的推广链接均会被禁用。</div>
        </el-form-item>
        <el-form-item label="微信二维码">
          <div class="pic_list_box">
            <span @click="delImage" class="delBtn"><i class="el-icon-error" v-if="imageUrl"></i></span>
            <div class="pic_list" v-if="imageUrl">
              <img :src="imageUrl" alt srcset />
            </div>
          <i class="el-icon-upload" v-else></i>
            <input
              type="file"
              :disabled="imageUrl? true : false"
              accept="jpg, png, gif"
              @change="changeImage($event)"
              ref="avatarInput"
              style="opacity: 0;
                    z-index: 10;
                    position: absolute;
                    height: 160px;
                   width: 200px;"
            />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 参数说明：
 * @showStatus => 弹框状态：0 - 关闭，1 - 添加，2 - 编辑
 * @roleOpts => 角色选项
 * @editData => 被编辑行
 */

import { base, baseUrl } from '@/api/env';
export default {
  props: {
    showStatus: {
      type: Number,
      default: 0
    },
    roleOpts: {
      type: Array,
      default: () => []
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    var checkPsd = (rule, value, callback) => {
      if (value === '' && this.showStatus !== 2) {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.repsd !== '') {
          this.$refs.form.validateField('repsd');
        }
      }
      callback();
    };
    var checkRepsd = (rule, value, callback) => {
      if (value === '' && this.showStatus !== 2) {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        // nick: '',
        username: '',
        mobile: '',
        password: '',
        repsd: '',
        roleId: '',
        status: null,
        wechat: ''
        // qq: ''
      },
      statusOpts: [
        { value: 0, label: '正常' },
        { value: 1, label: '禁用' }
      ],
      rules: {
        // nick: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          {
            pattern: /^[a-zA-z0-9]\w{3,15}$/,
            message: '账号应为4-16位字符，可含数字、字母、下划线',
            trigger: 'blur'
          }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确格式的手机号', trigger: 'blur' }
        ],
        wechat: [
          { required: true, message: '请输入微信号码', trigger: 'blur' },
          { pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/, message: '请输入正确格式的微信号', trigger: 'blur' }
        ],
        // qq:[{ required: true,message: '请输入qq号', trigger: 'blur' },
        // {pattern: /^[1-9]{1}[0-9]{4,11}$/, message: '请输入正确格式的qq号', trigger: 'blur' }],
        password: [
          { validator: checkPsd, trigger: 'blur' },
          {
            pattern: /^[a-zA-z0-9]\w{5,19}$/,
            message: '6-20位字符，由数字、字母、下划线组成，且不能以下划线开头',
            trigger: 'blur'
          }
        ],
        repsd: [{ validator: checkRepsd, trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      imageUrl: "",
      imageID: "",
      showImg: false,
      width: '45%'
    };
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields();
      this.$emit('cancel');
    },
    submit() {
      // 添加账号表单校验
      let check = true;
      this.$refs['form'].validate(res => (check = res));
      if (!check) return;

      this.form.wechatQrCode = this.imageID;
      this.$emit('submit-form', this.form);
      this.imageUrl = '';
      this.imageID = "";
      this.$refs['form'].resetFields();
    },
    changeImage(e) {
      // 上传图片事件
      var files = this.$refs.avatarInput.files;
      let param = new FormData();
      param.append('file', files[0]);
      this.$axios
        .post(base + 'api/common/attach', param, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          this.imageID = res.data.id;
          this.imageUrl = res.data.url;
        })
        .catch(err => {
          // return reject(err);
        });
    },
    openDialog() {
      // 填充编辑表单数据
      if(document.documentElement.clientWidth < 480){
        this.width = '95%'
      }
      if (this.showStatus === 2) {
        this.$nextTick(() => {
          let { form, editData, roleOpts } = this;
          let { roleName = '', status, qq, wechat, password } = editData;
          // 匹配角色
          let matchRole = roleOpts.find(item => item.label === roleName);
          form.roleId = matchRole ? matchRole.value : '';
          form.status = status;
          // form.qq = qq;
          form.wechat = wechat;
          /*
          form.password = password;
          form.repsd = password;
           */
          this.showImg = editData.wechatQrcode?true:false
          this.imageUrl =  this.showImg? base+'api/common/attach/'+editData.wechatQrcode:''
        });
      }
    },
    //删除二维码
    delImage(){
      this.showImg = false;
      this.imageUrl = '';
      this.imageID = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.add {
  .form {
    width: 85%;
    text-align: left;
    margin: 0 auto;
  }
}
.pic_list_box {
  display: flex;
  border: 1px dashed #d9d5d5;
  // background: url('../../');
    position: relative;height: 160px;
  width: 200px;
  .delBtn{
    z-index: 100;
    display: inline-block;
    width:18px;
    height:18px;
    line-height: 18px;
    text-align: center;
  }
  .upload_btn {
    width: 80px;
    height: 80px;
    padding-left: 15px;
  }
.el-icon-upload{
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left:-16px;
      margin-top: -16px;
    }
  .pic_list {
    margin: 4px auto;
    img {
      width: 160px;
      height: 160px;

      padding-bottom: 10px;margin: 0 auto;
    }
  }
}
</style>
