<template>
  <div class="user-box">
    <!-- 搜索栏 -->
    <search-form @search="searchList" @show-add="showStatus = 1"></search-form>
    <!-- 账号列表 -->
    <account-table :list="accountList" @show-edit="showEdit" @delete="handleDel"></account-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
    <!-- 添加账号 -->
    <add-form
      :show-status="showStatus"
      :role-opts="roleOpts"
      :edit-data="editData"
      @cancel="showStatus = 0"
      @submit-form="submit"
    ></add-form>
  </div>
</template>

<script>
// components
import searchForm from './components/search-form';
import accountTable from './components/account-table';
import addForm from './components/add-form';
// api
import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      accountList: [],
      page: {}, // 页码
      showStatus: 0,
      editData: {},
      roleOpts: [],
      searchData: {}
    };
  },
  components: {
    searchForm,
    accountTable,
    addForm
  },
  methods: {
    // 初始化
    init() {
      let initParam = { pageNum: 1 };
      this.getList(initParam);
      this.getRoles();
    },
    // 获取角色选项
    getRoles() {
      return service.roles().then(res => {
        this.roleOpts = res.reduce((prev, cur) => (prev.push({ value: cur.id, label: cur.roleName }), prev), []);
      });
    },
    // 获取账户列表
    getList(params) {
      // 切换分页当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.getAccountList(params).then(res => {
        let { page = {}, list = [] } = res;
        this.page = page;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.accountList = list;
      });
    },
    // 搜索
    searchList(data) {
      let params = { pageNum: 1, ...data };
      this.searchData = data;
      return this.getList(params);
    },
    // 显示编辑框
    showEdit(row) {
      this.showStatus = 2;
      this.editData = row;
    },
    // 提交表单
    submit(data) {
      if (this.showStatus === 2) {
        // 编辑账号
        let edit = { password: '', roleId: null, status: null, wechat: '', wechatQrCode: '' }; // qq:'',
        for (let key in edit) {
          edit[key] = data[key];
        }
        let params = { id: this.editData.id, ...edit };
        return this.handleSubmit(service.edit(params), { pageNum: this.currentPage });
      } else {
        // 添加账号
        return this.handleSubmit(service.add(data), { pageNum: 1 });
      }
    },
    // 统一处理提交
    handleSubmit(r, refreshParam) {
      return r.then(res => {
        this.showStatus = 0;
        this.$notify({ type: 'success', message: '操作成功！', offset: 400 });
        this.getList(refreshParam);
      });
    },
    //删除账号
    handleDel(row) {
      this.$alert(`确定将账号【${row.username || row.mobile}】永久删除吗？`, '提示', { showCancelButton: true }).then(
        () => {
          return service.del({ sonUserId: row.id }).then(res => {
            this.$notify({ type: 'success', message: '删除成功！', offset: 400 });
            this.init();
          });
        }
      );
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.user-box {
}
</style>
